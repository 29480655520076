import { FC } from 'react';
import LogoutIcon from '~/icons/LogoutIcon';
import Profile from '~/icons/Profile';
import SettingsIcon from '~/icons/SettingsIcon';

import { NavLink } from '@remix-run/react';

import styles from './HeaderUser.module.scss';
import { AppType } from '~/types';

interface Props {
  onLogout: () => Promise<void>;
  appType: AppType;
}

const UserDropdown: FC<Props> = ({ onLogout, appType }) => {
  const getUrl = (url: string) => appType === AppType.Raw ? `/r/${url}` : url;

  return (
    <div className={styles.dropdown}>
      <NavLink className={styles.dropdownButton} to={getUrl('user-profile')}>
        <Profile />
        View Profile
      </NavLink>
      <NavLink className={styles.dropdownButton} to={getUrl('user-settings')}>
        <SettingsIcon />
        Settings
      </NavLink>
      <div className={styles.dropdownBottom}>
        <button className={styles.dropdownButton} onClick={onLogout}>
          <LogoutIcon />
          Log out
        </button>
      </div>
    </div>
  );
};

export default UserDropdown;
