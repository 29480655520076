import classNames from 'classnames';
import { FC, useRef } from 'react';
import { useUser } from '~/context/UserContext';
import useOnClickOutside from '~/hooks/useOnClickOutside';
import LinkIcon from '~/icons/LinkIcon';
import ReportRectangle from '~/icons/ReportRectangle';
import ScoreIcon from '~/icons/ScoreIcon';
import ThumbsDown from '~/icons/ThumbsDown';
import ThumbsUp from '~/icons/ThumbsUp';
import { Tag, TagStatistics } from '~/types';

import styles from './ArticleDotsDropdown.module.scss';

interface Props {
  version: number;
  stats: TagStatistics;
  tags: Tag[];
  articleId: number;
  hasTagStats: boolean;
  dropdownPosition: 'bottom' | 'top';
  onClose: () => void;
  setShowScoreModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowReportModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowFollowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowIgnoreModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowLoginModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ArticleDotsDropdown: FC<Props> = ({
  version,
  stats,
  tags,
  hasTagStats,
  articleId,
  dropdownPosition,
  onClose,
  setShowScoreModal,
  setShowReportModal,
  setShowFollowModal,
  setShowIgnoreModal,
  setShowLoginModal,
}) => {
  const { user } = useUser();

  const componentRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(componentRef, onClose);

  const formulaResult =
    version === 2 ? stats.formula_result_v2 : stats.formula_result;

  const favoriteTagsIds = user?.favorite_tags.map((tag) => tag.id);
  const ignoredTagsIds = user?.ignored_tags.map((tag) => tag.id);
  const articleTagsIds = tags.map((tag) => tag.id);
  const hasFavoriteTag = favoriteTagsIds?.some((favoriteTagId) =>
    articleTagsIds.includes(favoriteTagId),
  );
  const hasIgnoredTag = ignoredTagsIds?.some((favoriteTagId) =>
    articleTagsIds.includes(favoriteTagId),
  );

  const formulaMultiplier =
    hasFavoriteTag && hasIgnoredTag
      ? 5
      : hasFavoriteTag
      ? 10
      : hasIgnoredTag
      ? 0.5
      : 1;

  return (
    <div
      ref={componentRef}
      className={classNames(styles.dropdown, {
        [styles.top]: dropdownPosition === 'top',
        [styles.bottom]: dropdownPosition === 'bottom',
      })}
    >
      <button
        className={styles.actionButton}
        onClick={() => {
          if (!user) {
            return setShowLoginModal(true);
          }
          setShowFollowModal(true);
          onClose();
        }}
      >
        <ThumbsUp />
        See more like this
      </button>
      <button
        className={styles.actionButton}
        onClick={() => {
          if (!user) {
            return setShowLoginModal(true);
          }
          setShowIgnoreModal(true);
          onClose();
        }}
      >
        <ThumbsDown />
        See less like this
      </button>
      <button
        className={styles.actionButton}
        onClick={() => {
          setShowReportModal(true);
          onClose();
        }}
      >
        <ReportRectangle />
        Report
      </button>
      {user?.is_admin && (
        <div className={styles.adminActions}>
          {hasTagStats && (
            <button
              className={styles.actionButton}
              onClick={() => {
                setShowScoreModal(true);
                onClose();
              }}
            >
              <ScoreIcon />
              Score
              {!!formulaResult && (
                <>
                  :
                  <span>
                    {formulaResult} * {formulaMultiplier} ={' '}
                    {parseFloat(formulaResult) * formulaMultiplier}
                  </span>
                </>
              )}
            </button>
          )}
          <a
            className={styles.actionButton}
            target='blank'
            href={`https://api.bp.news/admin/articles/${articleId}/edit`}
          >
            <LinkIcon />
            Admin
          </a>
        </div>
      )}
    </div>
  );
};

export default ArticleDotsDropdown;
