import { motion } from 'framer-motion';
import { FC, useState } from 'react';
import ResponsiveImage from '~/components/ResponsiveImage/ResponsiveImage';
import { useUser } from '~/context/UserContext';
import { Article } from '~/types';

import { Link } from '@remix-run/react';

import AdminArticleInfo from '../AdminArticleInfo/AdminArticleInfo';
import ArticleActions from '../ArticleActions/ArticleActions';
import ArticleReferences from '../ArticleReferences/ArticleReferences';
import ArticleSeeMoreButton from '../ArticleSeeMoreButton/ArticleSeeMoreButton';
import ArticleTopicsAndDate from '../ArticleTopicsAndDate/ArticleTopicsAndDate';
import styles from './ArticleListCardDescription.module.scss';

interface Props {
  article: Article;
}

const ArticleListCardDescription: FC<Props> = ({ article }) => {
  const { user } = useUser();

  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <article className={styles.card}>
      <div className={styles.dateHolder}>
        <ArticleTopicsAndDate
          tags={article.tags}
          publishedAt={article.published_at}
        />
      </div>
      <div className={styles.headingRow}>
        <div>
          <Link
            className={styles.mainLink}
            to={`/article/${article.slug}`}
            aria-label={article.title}
          >
            <h2 className={styles.heading}>{article.title}</h2>
          </Link>
          {!!article.references?.length && (
            <div className={styles.referencesHolder}>
              <ArticleReferences
                references={article.references}
                tags={article.tags}
              />
            </div>
          )}
        </div>
        <Link to={`/article/${article.slug}`} aria-label={article.title}>
          <ResponsiveImage
            mediaData={article.media}
            alt={article.title}
            className={styles.image}
          />
        </Link>
      </div>
      {!!user?.is_admin && <AdminArticleInfo article={article} />}
      <motion.div
        initial={{ height: 104 }}
        animate={{ height: isExpanded ? 'auto' : 104 }}
        transition={{ duration: 0.2, ease: 'easeOut' }}
        className={styles.description}
      >
        <p>{article.description}</p>
      </motion.div>
      <div className={styles.buttonHolder}>
        <ArticleSeeMoreButton
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
        />
      </div>
      <div className={styles.actionsHolder}>
        <ArticleActions article={article} slug={article.slug} />
      </div>
    </article>
  );
};

export default ArticleListCardDescription;
