const DownVote = () => {
  return (
    <svg
      width='17'
      height='16'
      viewBox='0 0 17 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask id='path-1-inside-1_5423_53463' fill='white'>
        <path d='M11.5 1V7H14.5C15.1 7 15.5 7.3 15.5 8C15.5 8.5 15.2 8.8 14.9 9.1L9.7 15.3C9.3 15.7 9 16 8.5 16C8 16 7.7 15.7 7.3 15.3L2.1 9.1C1.8 8.8 1.5 8.5 1.5 8C1.5 7.3 1.9 7 2.5 7H5.5V1C5.5 0.4 5.9 0 6.5 0H10.5C11.1 0 11.5 0.4 11.5 1Z' />
      </mask>
      <path
        d='M11.5 1V7H14.5C15.1 7 15.5 7.3 15.5 8C15.5 8.5 15.2 8.8 14.9 9.1L9.7 15.3C9.3 15.7 9 16 8.5 16C8 16 7.7 15.7 7.3 15.3L2.1 9.1C1.8 8.8 1.5 8.5 1.5 8C1.5 7.3 1.9 7 2.5 7H5.5V1C5.5 0.4 5.9 0 6.5 0H10.5C11.1 0 11.5 0.4 11.5 1Z'
        stroke='currentColor'
        strokeWidth='3'
        mask='url(#path-1-inside-1_5423_53463)'
      />
    </svg>
  );
};

export default DownVote;
