const ThumbsDown = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3 4.5H7.5V14.25H3C2.80109 14.25 2.61032 14.171 2.46967 14.0303C2.32902 13.8897 2.25 13.6989 2.25 13.5V5.25C2.25 5.05109 2.32902 4.86032 2.46967 4.71967C2.61032 4.57902 2.80109 4.5 3 4.5V4.5Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.5 14.25L11.25 21.75C11.644 21.75 12.0341 21.6724 12.398 21.5216C12.762 21.3709 13.0927 21.1499 13.3713 20.8713C13.6499 20.5927 13.8709 20.262 14.0216 19.898C14.1724 19.5341 14.25 19.144 14.25 18.75V16.5H20.0508C20.2635 16.5 20.4738 16.4548 20.6677 16.3673C20.8615 16.2798 21.0346 16.1522 21.1753 15.9927C21.3161 15.8333 21.4213 15.6457 21.4841 15.4425C21.5468 15.2392 21.5656 15.025 21.5392 14.8139L20.4142 5.81395C20.3689 5.45117 20.1926 5.11744 19.9185 4.87548C19.6444 4.63353 19.2914 4.5 18.9258 4.5H7.5'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default ThumbsDown;
