import classNames from 'classnames';
import { FC, useEffect, useRef, useState } from 'react';
import useDebounce from '~/hooks/useDebounce';
import BackArrow from '~/icons/BackArrow';
import Close from '~/icons/Close';
import Search from '~/icons/Search';
import { MinimalTagResource } from '~/types';
import { getFeedType, renderSearchPageSlug } from '~/utils/helpers';
import { saveSearchTerm } from '~/utils/recentSearches';

import { Form, useLocation } from '@remix-run/react';

import Overlay from '../Overlay/Overlay';
import SearchDropdown from '../SearchDropdown/SearchDropdown';
import styles from './SearchBar.module.scss';

interface Props {
  topTags?: MinimalTagResource[];
  closeMenu: () => void;
}

const SearchBar: FC<Props> = ({ topTags, closeMenu }) => {
  const [isMobileSearchVisible, setIsMobileSearchVisible] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [hotTags, setHotTags] = useState<MinimalTagResource[]>([]);

  const [foundTopics, setFoundTopics] = useState<MinimalTagResource[]>([]);

  const [query, setQuery] = useState('');

  const componentRef = useRef<HTMLDivElement>(null);

  const inputRef = useRef<HTMLInputElement>(null);

  const location = useLocation();

  const pathSegments = location.pathname.split('/').filter(Boolean);
  const slug = pathSegments[0] || '';

  const feedType = getFeedType(location.pathname);

  const debouncedQuery = useDebounce(query, 300);

  const onTopicsSearch = async (query: string) => {
    try {
      const response = await fetch(`/search-topics`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ query, slug }),
      });

      const results = await response.json();

      return results;
    } catch (e) {
      return;
    }
  };

  useEffect(() => {
    if (debouncedQuery.length > 2) {
      const fetchData = async () => {
        const response = await onTopicsSearch(debouncedQuery);

        if (response && response.responseData.data) {
          setFoundTopics(response.responseData.data);
        } else {
          setFoundTopics([]);
        }
      };

      fetchData();
    } else {
      setFoundTopics([]);
    }
    // eslint-disable-next-line
  }, [debouncedQuery]);

  useEffect(() => {
    inputRef.current?.blur();
    setIsMobileSearchVisible(false);
  }, [location]);

  useEffect(() => {
    if (isMobileSearchVisible) inputRef.current?.focus();
  }, [isMobileSearchVisible]);

  const fetchHotTags = async (slug: string) => {
    const response = await fetch(`/hot-tags-by-page/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ slug }),
    });

    const data = await response.json();

    return data;
  };

  useEffect(() => {
    const loadRecommendations = async () => {
      const data = await fetchHotTags(slug);
      setHotTags(data.data);
    };

    loadRecommendations();
  }, [slug]);

  const handleSearchSubmit = () => {
    if (query) {
      saveSearchTerm(query);
    }
  };

  return (
    <>
      {!isMobileSearchVisible && (
        <button
          className={styles.mobileSearchButton}
          onClick={() => {
            setIsMobileSearchVisible((prev) => !prev);
            closeMenu();
            inputRef.current?.focus();
          }}
          type='button'
        >
          <Search />
        </button>
      )}
      <div
        ref={componentRef}
        className={classNames(styles.searchHolder, {
          [styles.searchHolderVisible]: isMobileSearchVisible,
        })}
      >
        <button
          className={styles.backButton}
          onClick={() => setIsMobileSearchVisible((prev) => !prev)}
        >
          <BackArrow />
        </button>
        <Form
          className={styles.searchForm}
          action={`${renderSearchPageSlug(feedType)}`}
          onSubmit={() => handleSearchSubmit()}
        >
          <div className={styles.holder}>
            <input
              ref={inputRef}
              className={styles.input}
              type='text'
              name='query'
              placeholder='Search BP News'
              value={query}
              autoComplete='off'
              onChange={(e) => setQuery(e.target.value)}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
            />
            <Search />
            {!!query.length && (
              <button
                onMouseDown={(e) => {
                  e.preventDefault();
                  setQuery('');
                }}
                className={styles.closeButton}
                aria-label='close search'
                type='button'
              >
                <Close />
              </button>
            )}
          </div>
        </Form>
        {!!isFocused && (
          <>
            <SearchDropdown
              query={query}
              foundTopics={foundTopics}
              topTags={topTags}
              hotTags={hotTags}
            />
            {isMobileSearchVisible && <Overlay />}
          </>
        )}
      </div>
    </>
  );
};

export default SearchBar;
