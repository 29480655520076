const Clock = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.5647 10.0001C18.5647 14.7297 14.7304 18.5649 9.99987 18.5649C5.27024 18.5649 1.43506 14.7297 1.43506 10.0001C1.43506 5.26953 5.27024 1.43527 9.99987 1.43527C14.7304 1.43527 18.5647 5.26953 18.5647 10.0001Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.8802 10.7104L9.68579 10.6418V6.15387'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Clock;
