const ScoreIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeMiterlimit='10'
      />
      <path
        d='M8.46649 16C7.99983 15.5125 7.62984 14.932 7.37804 14.2922C7.12623 13.6524 6.99786 12.9662 7.00003 12.2734C7.00003 10.8748 7.52678 9.53349 8.46446 8.54452C9.40213 7.55556 10.6739 7 12 7'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeMiterlimit='10'
      />
      <path
        d='M12 12L17 8'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeMiterlimit='10'
      />
      <path
        d='M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default ScoreIcon;
