import { FC } from 'react';
import ArticleListCardReferenceBased from '~/components/Article/ArticleCard/ArticleListCardReferenceBased';
import { useViewMode } from '~/context/ViewModeContext';
import { Article } from '~/types';

import ArticleListCardBullets from './ArticleListCardBullets';
import ArticleListCardDescription from './ArticleListCardDescription';

interface Props {
  article: Article;
  parentView?: string;
}

const ArticleListCard: FC<Props> = ({ article, parentView }) => {
  let { viewMode } = useViewMode();

  if (parentView) {
    viewMode = parentView;
  }

  return (
    <>
      {viewMode === 'bullets' ? (
        <ArticleListCardBullets article={article} />
      ) : viewMode === 'reference-based' ? (
        <ArticleListCardReferenceBased article={article} />
      ) : (
        <ArticleListCardDescription article={article} />
      )}
    </>
  );
};

export default ArticleListCard;
