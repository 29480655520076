import classNames from 'classnames';
import { AnimatePresence } from 'framer-motion';
import { FC, ReactElement, useEffect, useState } from 'react';
import { useUser } from '~/context/UserContext';
import Discover from '~/icons/Discover';
import Profile from '~/icons/Profile';
import Trending from '~/icons/Trending';
import { AppType, MinimalTagResource } from '~/types';
import { getPageTypeAndSlug } from '~/utils/helpers';

import { NavLink, useLocation } from '@remix-run/react';

import LogInModal from '../LogInModal/LogInModal';
import FollowedTopics from './FollowedTopics';
import MainTopics from './MainTopics';
import styles from './Sidebar.module.scss';
import SuggestedTopics from './SuggestedTopics';

interface Props {
  isMenuOpened: boolean;
  mainTags: MinimalTagResource[];
  isTextPage?: boolean;
  appType: AppType;
}

interface NavItem {
  icon: ReactElement;
  title: string;
  to: string;
  isUserRoute?: boolean;
}

const Sidebar: FC<Props> = ({ isMenuOpened, mainTags, isTextPage, appType }) => {
  const [showLogInModal, setShowLogInModal] = useState(false);
  const { user } = useUser();

  const [recommendedTags, setRecommendedTags] = useState<MinimalTagResource[]>(
    [],
  );
  const location = useLocation();
  const { type, slug } = getPageTypeAndSlug(location.pathname);

  const fetchTagRecommendations = async (type: string, slug: string) => {
    const response = await fetch(`/recommended-tags/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ type, slug }),
    });

    const data = await response.json();

    return data;
  };

  const userTags = user?.favorite_tags;

  useEffect(() => {
    const loadRecommendations = async () => {
      const data = await fetchTagRecommendations(type, slug);
      setRecommendedTags(data.data);
    };

    loadRecommendations();
  }, [slug, type, userTags]);

  const navItemsTop: NavItem[] = appType === AppType.Raw
    ? [
      { icon: <Trending />, title: 'Trending', to: '/r' },
      { icon: <Discover />, title: 'Discover', to: '/r/discover' },
    ]: [
      { icon: <Trending />, title: 'Trending', to: '/' },
      { icon: <Discover />, title: 'Discover', to: '/discover' },
    ];

  const navItemsBottom: NavItem[] = [
    {
      icon: <Profile />,
      title: 'Profile',
      to: '/user-profile',
      isUserRoute: true,
    },
  ];

  return (
    <>
      <aside
        className={classNames(styles.sidebar, {
          [styles.sidebarActive]: isMenuOpened,
          [styles.sidebarTextPage]: isTextPage,
        })}
      >
        <nav>
          <div className={styles.linksBlock}>
            <div className={styles.linksBlockInner}>
              {navItemsTop.map((item, index) =>
                !user && item.isUserRoute ? (
                  <button
                    onClick={() => setShowLogInModal(true)}
                    key={index}
                    className={styles.link}
                  >
                    {item.icon} {item.title}
                  </button>
                ) : (
                  <NavLink
                    key={index}
                    to={item.to}
                    className={({ isActive }) =>
                      classNames(styles.link, { [styles.linkActive]: isActive })
                    }
                    end
                  >
                    {item.icon} {item.title}
                  </NavLink>
                ),
              )}
            </div>
          </div>
          <div
            className={classNames(styles.linksBlock, styles.linksBlockTopics)}
          >
            <FollowedTopics
              appType={appType}
              tags={userTags}
              setShowLogInModal={setShowLogInModal}
            />
            {!!mainTags.length && (
              <MainTopics
                appType={appType}
                tags={mainTags}
                setShowLogInModal={setShowLogInModal}
              />
            )}
            {!!recommendedTags?.length && (
              <SuggestedTopics
                appType={appType}
                tags={recommendedTags}
                setShowLogInModal={setShowLogInModal}
              />
            )}
          </div>
          <div className={styles.linksBlock}>
            <div className={styles.linksBlockInner}>
              {navItemsBottom.map((item, index) =>
                !user && item.isUserRoute ? (
                  <button
                    onClick={() => setShowLogInModal(true)}
                    key={index}
                    className={styles.link}
                  >
                    {item.icon} {item.title}
                  </button>
                ) : (
                  <NavLink
                    key={index}
                    to={item.to}
                    className={({ isActive }) =>
                      classNames(styles.link, { [styles.linkActive]: isActive })
                    }
                  >
                    {item.icon} {item.title}
                  </NavLink>
                ),
              )}
            </div>
          </div>
        </nav>
      </aside>
      <AnimatePresence>
        {showLogInModal && (
          <LogInModal onClose={() => setShowLogInModal(false)} />
        )}
      </AnimatePresence>
    </>
  );
};

export default Sidebar;
