const UpVote = () => {
  return (
    <svg
      width='17'
      height='16'
      viewBox='0 0 17 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask id='path-1-inside-1_5423_53460' fill='white'>
        <path d='M11.5 15V9H14.5C15.1 9 15.5 8.7 15.5 8C15.5 7.5 15.2 7.2 14.9 6.9L9.7 0.7C9.3 0.3 9 0 8.5 0C8 0 7.7 0.3 7.3 0.7L2.1 6.9C1.8 7.2 1.5 7.5 1.5 8C1.5 8.7 1.9 9 2.5 9H5.5V15C5.5 15.6 5.9 16 6.5 16H10.5C11.1 16 11.5 15.6 11.5 15Z' />
      </mask>
      <path
        d='M11.5 15V9H14.5C15.1 9 15.5 8.7 15.5 8C15.5 7.5 15.2 7.2 14.9 6.9L9.7 0.7C9.3 0.3 9 0 8.5 0C8 0 7.7 0.3 7.3 0.7L2.1 6.9C1.8 7.2 1.5 7.5 1.5 8C1.5 8.7 1.9 9 2.5 9H5.5V15C5.5 15.6 5.9 16 6.5 16H10.5C11.1 16 11.5 15.6 11.5 15Z'
        stroke='currentColor'
        strokeWidth='3'
        mask='url(#path-1-inside-1_5423_53460)'
      />
    </svg>
  );
};

export default UpVote;
