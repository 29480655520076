import { AnimatePresence } from 'framer-motion';
import { FC, useState } from 'react';

import ImageReportModal from '../ImageReportModal/ImageReportModal';
import styles from './ImageReportBlock.module.scss';

interface Props {
  articleId: number;
}

const ImageReportBlock: FC<Props> = ({ articleId }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div className={styles.box}>
        <div className={styles.reportText}>
          <svg
            width='12'
            height='13'
            viewBox='0 0 12 13'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M1.375 6.5C1.375 9.9685 2.5315 11.125 6 11.125C9.4685 11.125 10.625 9.9685 10.625 6.5C10.625 3.0315 9.4685 1.875 6 1.875C2.5315 1.875 1.375 3.0315 1.375 6.5Z'
              stroke='#EAECEF'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M10.5604 7.83322C10.1199 7.38022 9.4969 6.46472 8.3524 6.46472C7.2079 6.46472 7.1829 8.48372 6.0149 8.48372C4.8464 8.48372 4.3759 7.79822 3.6144 8.15622C2.8534 8.51372 2.2334 9.93672 2.2334 9.93672'
              stroke='#EAECEF'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M5.29909 4.8921C5.29909 5.3786 4.90509 5.7726 4.41859 5.7726C3.93259 5.7726 3.53809 5.3786 3.53809 4.8921C3.53809 4.4056 3.93259 4.0116 4.41859 4.0116C4.90509 4.0116 5.29909 4.4056 5.29909 4.8921Z'
              stroke='#EAECEF'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
          Generated image for illustrative purposes
        </div>
        <button onClick={() => setShowModal(true)} className={styles.button}>
          <svg
            width='12'
            height='13'
            viewBox='0 0 12 13'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M1.25 6.5C1.25 2.93776 2.43776 1.75 6 1.75C9.56224 1.75 10.75 2.93776 10.75 6.5C10.75 10.0622 9.56224 11.25 6 11.25C2.43776 11.25 1.25 10.0622 1.25 6.5Z'
              stroke='#EAECEF'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M5.99775 8.375H6.00225'
              stroke='#EAECEF'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M6 4.67749V6.62499'
              stroke='#EAECEF'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
          Report
        </button>
      </div>
      <AnimatePresence>
        {showModal && (
          <ImageReportModal
            articleId={articleId}
            onClose={() => setShowModal(false)}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default ImageReportBlock;
