import classNames from 'classnames';
import { motion } from 'framer-motion';
import { FC, useEffect, useState } from 'react';
import { useFetcher } from 'react-router-dom';
import Spinner from '~/components/Spinner/Spinner';
import { useToasts } from '~/context/ToastContext';
import { useUser } from '~/context/UserContext';
import Close from '~/icons/Close';
import formStyles from '~/scss/layout/form.module.scss';
import { Tag } from '~/types';

import styles from './ArticleTopicsModals.module.scss';
import FollowTagBullet from './FollowTagBullet';

interface Props {
  tags: Tag[];
  onClose: () => void;
}

const ArticleFollowTopicsModal: FC<Props> = ({ onClose, tags }) => {
  const { createToast } = useToasts();

  const { user } = useUser();
  const [selectedTopicsIds, setSelectedTopicsIds] = useState<number[]>([]);

  const favoritedTagsIds = user?.favorite_tags.map((tag) => tag.id);
  const filteredTags = tags.filter(
    (tag) => !favoritedTagsIds?.includes(tag.id),
  );

  const fetcher = useFetcher();
  const isSubmitting = fetcher.state === 'submitting';

  useEffect(() => {
    if (fetcher.data?.status === 200 && fetcher.state === 'idle') {
      createToast('success', 'Topics preferences have been updated');
      onClose();
    }
  }, [fetcher.data, fetcher.state, createToast, onClose]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.1 }}
      className={styles.modalOverlay}
    >
      <div className={styles.modal}>
        <fetcher.Form method='post' action='/follow-tags'>
          <input
            type='text'
            name='tag_ids'
            hidden
            value={selectedTopicsIds.join(',')}
          />
          <div className={styles.content}>
            <div className={styles.headingHolder}>
              <div className={styles.heading}>See More like this </div>
              <button className={styles.closeButton} onClick={onClose}>
                <Close />
              </button>
            </div>
            <div className={styles.description}>
              <p>Select tags you want to see more</p>
            </div>
            <div className={styles.subHeading}>article topics</div>
            <div className={styles.tags}>
              {filteredTags.length ? (
                filteredTags.map((tag) => (
                  <FollowTagBullet
                    key={tag.id}
                    tag={tag}
                    selectedTopicsIds={selectedTopicsIds}
                    setSelectedTopicsIds={setSelectedTopicsIds}
                  />
                ))
              ) : (
                <div className={styles.noTopics}>
                  All the topics in this article have already been followed.
                </div>
              )}
            </div>
          </div>
          <div className={styles.footer}>
            <button
              disabled={isSubmitting || !selectedTopicsIds.length}
              className={classNames(formStyles.submitButton)}
              type='submit'
            >
              {isSubmitting ? <Spinner /> : 'Save & Close'}
            </button>
          </div>
        </fetcher.Form>
      </div>
    </motion.div>
  );
};

export default ArticleFollowTopicsModal;
