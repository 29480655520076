import { FC } from 'react';
import { useUser } from '~/context/UserContext';
import { Tag, TagStatistics } from '~/types';

import tagStyles from './TagStats.module.scss';
import TagStatsRow from './TagStatsRow';

interface Props {
  tags: Tag[];
  stats: TagStatistics;
  version: number;
}

const TagStats: FC<Props> = ({ stats, version, tags }) => {
  const { user } = useUser();

  if (!stats.formula && !stats.formula_result_v2) return null;

  const formula = version === 2 ? stats.formula_v2 : stats.formula;

  function extractKeysAndOperations(formula: string) {
    const regex = /(\w+)\s*([*/+-])\s*(\d+)/g;

    let matches;
    const result = [];

    while ((matches = regex.exec(formula)) !== null) {
      const key = matches[1];
      const operator = matches[2];
      const operatorValue = parseInt(matches[3], 10);
      result.push({ key, operator, operatorValue });
    }

    return result;
  }

  const keysAndOperations = extractKeysAndOperations(formula);

  const finalObjectArray = keysAndOperations.map((item) => {
    const keyValue = stats[item.key as keyof TagStatistics];
    return {
      key: item.key,
      operator: item.operator,
      operatorValue: item.operatorValue,
      keyValue,
    };
  });

  let formulaWithValues = formula;

  finalObjectArray.forEach((item) => {
    const calculatedValue = evaluateOperation(
      item.keyValue,
      item.operator,
      item.operatorValue,
    );
    const regex = new RegExp(
      `\\(${item.key}\\s*\\${item.operator}\\s*${item.operatorValue}\\)`,
      'g',
    );
    formulaWithValues = formulaWithValues.replace(regex, `${calculatedValue}`);
  });

  function evaluateOperation(
    keyValue: string,
    operator: string,
    operatorValue: number,
  ): number {
    switch (operator) {
      case '*':
        return parseFloat(keyValue) * operatorValue;
      case '/':
        return parseFloat(keyValue) / operatorValue;
      case '+':
        return parseFloat(keyValue) + operatorValue;
      case '-':
        return parseFloat(keyValue) - operatorValue;
      default:
        return 0;
    }
  }

  const formulaResult =
    version === 2 ? stats.formula_result_v2 : stats.formula_result;

  const updatedFormulaResult =
    version === 2 ? null : stats.updated_formula_result;

  const favoriteTagsIds = user?.favorite_tags.map((tag) => tag.id);
  const ignoredTagsIds = user?.ignored_tags.map((tag) => tag.id);
  const articleTagsIds = tags.map((tag) => tag.id);
  const hasFavoriteTag = favoriteTagsIds?.some((favoriteTagId) =>
    articleTagsIds.includes(favoriteTagId),
  );
  const hasIgnoredTag = ignoredTagsIds?.some((favoriteTagId) =>
    articleTagsIds.includes(favoriteTagId),
  );

  const formulaMultiplier =
    hasFavoriteTag && hasIgnoredTag
      ? 5
      : hasFavoriteTag
      ? 10
      : hasIgnoredTag
      ? 0.5
      : 1;

  return (
    <>
      <h4 className={tagStyles.statsHeading}>Main Topic: {stats.title}</h4>
      <ul className={tagStyles.statsList}>
        {finalObjectArray.map((result, index) => (
          <TagStatsRow result={result} key={index} />
        ))}
        {version === 2 ? (
          <>
            {stats.hasOwnProperty('sum_v2') && (
              <>
                <li className={tagStyles.statsListItem}>
                  Avg FB engagement median diffs:{' '}
                  <strong>{stats.sum_v2}</strong>
                </li>
                <li className={tagStyles.statsListItem}>
                  References count: <strong>{stats.references_count_v2}</strong>
                </li>
                <li className={tagStyles.statsListItem}>
                  Formula:{' '}
                  <strong>
                    min((sum / references_count) + references_count, 30)
                  </strong>
                </li>
              </>
            )}
            <li className={tagStyles.statsListItem}>
              Score:{' '}
              <strong>
                {formulaResult} * {formulaMultiplier} ={' '}
                {parseFloat(formulaResult) * formulaMultiplier}
              </strong>
            </li>
          </>
        ) : (
          <>
            <li className={tagStyles.statsListItem}>
              Formula result: <strong>{formulaResult}</strong>
              <br />
              <span>
                ({formulaWithValues} = {formulaResult})
              </span>
            </li>
            {!!updatedFormulaResult && (
              <li className={tagStyles.statsListItem}>
                Updated Formula result: <strong>{updatedFormulaResult}</strong>
              </li>
            )}
          </>
        )}
      </ul>
    </>
  );
};

export default TagStats;
