import { AnimatePresence } from 'framer-motion';
import { FC, useState } from 'react';
import LogInModal from '~/components/LogInModal/LogInModal';
import { useUser } from '~/context/UserContext';
import { Reference, Tag } from '~/types';

import ArticleIgnoreTopicsModal from '../ArticleTopicsModals/ArticleIgnoreTopicsModal';
import styles from './ArticleReferences.module.scss';

interface Props {
  references: Reference[];
  tags: Tag[];
}

const formatHost = (host: string): string => {
  const trimmedHost = host.startsWith('www.') ? host.slice(4) : host;

  return trimmedHost.charAt(0).toUpperCase() + trimmedHost.slice(1);
};

const getReferencesTitle = (references: Reference[]) => {
  if (!references || references.length === 0) {
    return '0 references';
  }

  try {
    const firstReferenceHost = new URL(references[0].url).host;
    const formattedHost = formatHost(firstReferenceHost);

    return references.length > 1
      ? `${formattedHost} and ${references.length - 1} more`
      : formattedHost;
  } catch (e) {
    return `${references.length} references`;
  }
};

const ArticleReferences: FC<Props> = ({ references, tags }) => {
  const [showIgnoreModal, setShowIgnoreModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);

  const { user } = useUser();

  const title = getReferencesTitle(references);

  const first3referencesFavicons = references
    .filter((reference) => reference.scraper_icon)
    .reduce<Reference[]>((uniqueReferences, reference) => {
      const isDuplicate = uniqueReferences.some(
        (uniqueRef) => uniqueRef.scraper_icon === reference.scraper_icon,
      );
      if (!isDuplicate) {
        uniqueReferences.push(reference);
      }
      return uniqueReferences;
    }, [])
    .slice(0, 3);

  return (
    <div className={styles.row}>
      <div className={styles.innerRow}>
        {!!first3referencesFavicons.length && (
          <div className={styles.favIconsRow}>
            {first3referencesFavicons.map((reference) => (
              <img
                className={styles.favIcon}
                key={reference.id}
                src={reference.scraper_icon}
                alt={reference.scraper_icon}
              />
            ))}
          </div>
        )}
        <div className={styles.title}>{title}</div>
      </div>
      <button
        className={styles.actionButton}
        onClick={() => {
          if (!user) {
            return setShowLoginModal(true);
          }
          setShowIgnoreModal(true);
        }}
      >
        See less like this
      </button>
      <AnimatePresence>
        {showIgnoreModal && (
          <ArticleIgnoreTopicsModal
            tags={tags}
            onClose={() => setShowIgnoreModal(false)}
          />
        )}
        {showLoginModal && (
          <LogInModal onClose={() => setShowLoginModal(false)} />
        )}
      </AnimatePresence>
    </div>
  );
};

export default ArticleReferences;
