const RECENT_SEARCHES_KEY = 'recentSearches';

export const saveSearchTerm = (term: string) => {
  let searches = JSON.parse(localStorage.getItem(RECENT_SEARCHES_KEY) || '[]');
  searches = [term, ...searches.filter((t: string) => t !== term)].slice(0, 3);
  localStorage.setItem(RECENT_SEARCHES_KEY, JSON.stringify(searches));
};

export const getRecentSearches = () => {
  return JSON.parse(localStorage.getItem(RECENT_SEARCHES_KEY) || '[]');
};

export const removeSearchFromLocalStorage = (termToRemove: string) => {
  const searches = JSON.parse(
    localStorage.getItem(RECENT_SEARCHES_KEY) || '[]',
  );

  const updatedSearches = searches.filter(
    (term: string) => term !== termToRemove,
  );

  localStorage.setItem(RECENT_SEARCHES_KEY, JSON.stringify(updatedSearches));

  return updatedSearches;
};
