import { FC } from 'react';
import ArticleReferences from '~/components/Article/ArticleReferences/ArticleReferences';
import ResponsiveImage from '~/components/ResponsiveImage/ResponsiveImage';
import { Article } from '~/types';

import { Link } from '@remix-run/react';

import ArticleActions from '../ArticleActions/ArticleActions';
import ArticleTopicsAndDate from '../ArticleTopicsAndDate/ArticleTopicsAndDate';
import listStyles from './ArticleListCardReferenceBased.module.scss';

interface Props {
  article: Article;
}

const ArticleListCardReferenceBased: FC<Props> = ({ article }) => {
  return (
    <div className={listStyles.card}>
      <article className={listStyles.cardArticle}>
        <div className={listStyles.imageHolder}>
          <Link to={`/r/article/${article.slug}`} aria-label={article.title}>
            <ResponsiveImage
              mediaData={article.media}
              alt={article.title}
              className={listStyles.img}
            />
          </Link>
        </div>
        <div className={listStyles.cardBottom}>
          <div className={listStyles.dateHolder}>
            <ArticleTopicsAndDate
              tags={article.tags}
              publishedAt={article.published_at}
            />
          </div>
          <Link className={listStyles.link} to={`/r/article/${article.slug}`}>
            <h2 className={listStyles.heading}>{article.title}</h2>
          </Link>
          {!!article.references?.length && (
            <div className={listStyles.referencesHolder}>
              <ArticleReferences
                references={article.references}
                tags={article.tags}
              />
            </div>
          )}
          <div className={listStyles.actionsHolder}>
            <ArticleActions article={article} slug={article.slug} />
          </div>
        </div>
      </article>
    </div>
  );
};

export default ArticleListCardReferenceBased;
