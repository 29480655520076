const Minus = () => {
  return (
    <svg
      width='10'
      height='2'
      viewBox='0 0 10 2'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.25052 0.999978H0.746094'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Minus;
