import { FC, useRef } from 'react';
import Modal from '~/components/Modal/Modal';
import useOnClickOutside from '~/hooks/useOnClickOutside';
import Close from '~/icons/Close';
import { Article } from '~/types';

import TagStats from '../TagStats/TagStats';
import styles from './ArticleScoreModal.module.scss';

interface Props {
  article: Article;
  onClose: () => void;
}

const ArticleScoreModal: FC<Props> = ({ article, onClose }) => {
  const componentRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(componentRef, onClose);
  return (
    <Modal>
      <div ref={componentRef} className={styles.modal}>
        <button className={styles.closeButton} onClick={onClose}>
          <Close />
        </button>
        {!!article.tag_stats && (
          <TagStats
            stats={article.tag_stats}
            version={article.auto_generation_version}
            tags={article.tags}
          />
        )}
      </div>
    </Modal>
  );
};

export default ArticleScoreModal;
