import classNames from 'classnames';
import { FC, useEffect } from 'react';
import Modal from '~/components/Modal/Modal';
import Spinner from '~/components/Spinner/Spinner';
import { useToasts } from '~/context/ToastContext';
import ErrorIcon from '~/icons/ErrorIcon';
import formStyles from '~/scss/layout/form.module.scss';
import '~/scss/layout/select.scss';

import { useFetcher } from '@remix-run/react';

import styles from './ContentReportModal.module.scss';

interface ActionData {
  errors?: {
    reason?: string;
  };
  status: number;
}

interface Props {
  articleId: number;
  onClose: () => void;
}

const ContentReportModal: FC<Props> = ({ onClose, articleId }) => {
  const { createToast } = useToasts();

  const reportedArticles = JSON.parse(
    localStorage.getItem('reportedArticlesContent') || '[]',
  );

  const isArticleReported = reportedArticles.includes(articleId);

  const fetcher = useFetcher<ActionData>();
  const isSubmitting = fetcher.state === 'submitting';
  const errors = fetcher.data?.errors;

  useEffect(() => {
    if (fetcher.data?.status === 200 && fetcher.state === 'idle') {
      if (!reportedArticles.includes(articleId)) {
        reportedArticles.push(articleId);
        localStorage.setItem(
          'reportedArticlesContent',
          JSON.stringify(reportedArticles),
        );
        createToast('success', 'Your report has been submitted');
      }
      onClose();
    }
  }, [
    fetcher.data?.status,
    fetcher.state,
    articleId,
    reportedArticles,
    createToast,
    onClose,
  ]);

  return (
    <Modal>
      <div className={styles.reportModal}>
        <h3 className={styles.heading}>Report Article Content</h3>
        <fetcher.Form method='post' action='/article-report'>
          {isArticleReported ? (
            <>
              <p className={styles.description}>
                Sorry, it looks like you have already reported this article.
              </p>
              <button
                onClick={onClose}
                type='button'
                className={formStyles.button}
              >
                Cancel
              </button>
            </>
          ) : (
            <>
              <div className={styles.formLayout}>
                <div>
                  <label className={formStyles.label} htmlFor='reason-text'>
                    Reason for reporting
                  </label>
                  <textarea
                    className={classNames(formStyles.textarea, {
                      [formStyles.error]: !!errors?.reason,
                    })}
                    id='reason-text'
                    name='reason-text'
                    placeholder='Add text'
                  />
                  {errors?.reason && (
                    <div className={formStyles.errorMessage}>
                      <ErrorIcon />
                      {errors.reason}
                    </div>
                  )}
                </div>
              </div>
              <div className={formStyles.buttons}>
                <button
                  type='submit'
                  className={classNames(
                    formStyles.button,
                    formStyles.buttonRed,
                  )}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? <Spinner /> : 'Report'}
                </button>
                <button
                  onClick={onClose}
                  type='button'
                  className={formStyles.button}
                >
                  Cancel
                </button>
              </div>
              <input hidden name='article_id' defaultValue={articleId} />
              <input hidden name='type' value='content' readOnly />
            </>
          )}
        </fetcher.Form>
      </div>
    </Modal>
  );
};

export default ContentReportModal;
