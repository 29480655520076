import classNames from 'classnames';
import { AnimatePresence } from 'framer-motion';
import { FC, useState } from 'react';
import LogInModal from '~/components/LogInModal/LogInModal';
import { useToasts } from '~/context/ToastContext';
import { useUser } from '~/context/UserContext';
import CommentIcon from '~/icons/CommentIcon';
import DownVote from '~/icons/DownVote';
import UpVote from '~/icons/UpVote';
import { Article } from '~/types';
import { VoteType } from '~/types/enums';
import { capitalizeText } from '~/utils/helpers';

import { Link, useRevalidator } from '@remix-run/react';

import ArticleDotsActions from '../ArticleDotsActions/ArticleDotsActions';
import styles from './ArticleActions.module.scss';

interface Props {
  article: Article;
  slug?: string;
}

const ArticleActions: FC<Props> = ({ article, slug }) => {
  const { user } = useUser();

  const commentsCount = article.comments.length ?? 0;
  const votesCount = article.votes_count ?? 0;

  const getStartingVoteSum = () => {
    if (article.tag_stats?.updated_formula_result) {
      return Math.round(
        parseFloat(article.tag_stats.updated_formula_result) + votesCount,
      );
    }
    if (article.tag_stats?.formula_result) {
      return Math.round(
        parseFloat(article.tag_stats.formula_result) + votesCount,
      );
    }
    if (article.tag_stats?.formula_result_v2) {
      return Math.round(
        parseFloat(article.tag_stats.formula_result_v2) + votesCount,
      );
    }
    return votesCount;
  };
  const [isUpvote, setIsUpvote] = useState(
    article.votes_meta.current_user_vote_type === VoteType.Up,
  );

  const [isDownvote, setIsDownvote] = useState(
    article.votes_meta.current_user_vote_type === VoteType.Down,
  );

  const [showModal, setShowModal] = useState(false);

  const [voteCount, setVoteCount] = useState(getStartingVoteSum());

  const revalidator = useRevalidator();

  const { createToast } = useToasts();

  const onVote = async (voteType: VoteType) => {
    if (!user) {
      return setShowModal(true);
    }

    try {
      setVoteCount((prev) => {
        if (voteType === VoteType.Up) {
          if (isUpvote) {
            return prev - 1;
          } else if (isDownvote) {
            return prev + 2;
          } else {
            return prev + 1;
          }
        } else if (voteType === VoteType.Down) {
          if (isUpvote) {
            return prev - 2;
          } else if (isDownvote) {
            return prev + 1;
          } else {
            return prev - 1;
          }
        }
        return prev;
      });

      if (voteType === VoteType.Up) {
        setIsUpvote(!isUpvote);
        isDownvote && setIsDownvote(false);
      }

      if (voteType === VoteType.Down) {
        setIsDownvote(!isDownvote);
        isUpvote && setIsUpvote(false);
      }

      const response = await fetch(`/vote/${article.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ voteType }),
      });

      const awaitedResponse = await response.json();

      if (awaitedResponse.followed_tags.length) {
        const tagNames: string[] = awaitedResponse.followed_tags.map(
          (tag: string) => capitalizeText(tag),
        );

        const formattedTags = tagNames.join(', ');

        createToast(
          'success',
          `${formattedTags} added to your Followed Topics`,
        );
        revalidator.revalidate();
      }

      if (awaitedResponse.ignored_tags.length) {
        const tagNames: string[] = awaitedResponse.ignored_tags.map(
          (tag: string) => capitalizeText(tag),
        );

        const formattedTags = tagNames.join(', ');

        createToast('success', `${formattedTags} moved to your Ignored Topics`);
        revalidator.revalidate();
      }
    } catch (e) {
      return;
    }
  };

  return (
    <div className={classNames(styles.actions)}>
      <div className={styles.votes}>
        <div className={styles.votesHolder}>
          <button
            onClick={(e) => {
              e.preventDefault();
              onVote(VoteType.Up);
            }}
            className={classNames(styles.voteButton, styles.voteButtonUp, {
              [styles.active]: isUpvote,
            })}
            aria-label='Upvote'
          >
            <UpVote />
          </button>
          <div className={styles.votesCount}>Vote {voteCount}</div>
          <button
            onClick={(e) => {
              e.preventDefault();
              onVote(VoteType.Down);
            }}
            className={classNames(styles.voteButton, styles.voteButtonDown, {
              [styles.active]: isDownvote,
            })}
            aria-label='Downvote'
          >
            <DownVote />
          </button>
        </div>
        <Link
          to={slug ? `/article/${slug}#comments` : '#comments'}
          className={styles.commentButton}
          aria-label='Comment'
        >
          <CommentIcon />
          {commentsCount}
        </Link>
        <ArticleDotsActions article={article} />
      </div>
      <AnimatePresence>
        {showModal && <LogInModal onClose={() => setShowModal(false)} />}
      </AnimatePresence>
    </div>
  );
};

export default ArticleActions;
