const ThumbsUp = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3 9.75H7.5V19.5H3C2.80109 19.5 2.61032 19.421 2.46967 19.2803C2.32902 19.1397 2.25 18.9489 2.25 18.75V10.5C2.25 10.3011 2.32902 10.1103 2.46967 9.96967C2.61032 9.82902 2.80109 9.75 3 9.75V9.75Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.5 9.75L11.25 2.25C11.644 2.25 12.0341 2.3276 12.398 2.47836C12.762 2.62913 13.0927 2.8501 13.3713 3.12868C13.6499 3.40726 13.8709 3.73797 14.0216 4.10195C14.1724 4.46593 14.25 4.85603 14.25 5.25V7.5H20.0508C20.2635 7.5 20.4738 7.54523 20.6677 7.63269C20.8615 7.72016 21.0346 7.84785 21.1753 8.0073C21.3161 8.16675 21.4213 8.35431 21.4841 8.55753C21.5468 8.76076 21.5656 8.975 21.5392 9.18605L20.4142 18.1861C20.3689 18.5488 20.1926 18.8826 19.9185 19.1245C19.6444 19.3665 19.2914 19.5 18.9258 19.5H7.5'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default ThumbsUp;
