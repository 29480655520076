import { useEffect, useState } from 'react';
import Clock from '~/icons/Clock';
import Close from '~/icons/Close';
import {
  getRecentSearches,
  removeSearchFromLocalStorage,
} from '~/utils/recentSearches';

import { NavLink } from '@remix-run/react';

import styles from './RecentSearches.module.scss';

const RecentSearches = () => {
  const [searches, setSearches] = useState<string[]>([]);

  useEffect(() => {
    const storedSearches = getRecentSearches();
    setSearches(storedSearches);
  }, []);

  return (
    <div>
      {searches.map((search, index) => (
        <div key={index} className={styles.item}>
          <NavLink to={`/search?query=${search}`} className={styles.link}>
            <Clock />
            {search}
          </NavLink>
          <button
            className={styles.removeButton}
            onClick={() => {
              const newSearches = removeSearchFromLocalStorage(search);
              setSearches(newSearches);
            }}
          >
            <Close />
          </button>
        </div>
      ))}
    </div>
  );
};

export default RecentSearches;
