import classNames from 'classnames';
import { FC } from 'react';
import Plus from '~/icons/Plus';
import { Tag } from '~/types';

import styles from './TagBullet.module.scss';

interface Props {
  tag: Tag;
  selectedTopicsIds: number[];
  setSelectedTopicsIds: React.Dispatch<React.SetStateAction<number[]>>;
}
const FollowTagBullet: FC<Props> = ({
  tag,
  selectedTopicsIds,
  setSelectedTopicsIds,
}) => {
  const onTagToggle = () => {
    setSelectedTopicsIds((prev) => {
      if (prev.includes(tag.id)) {
        return prev.filter((id) => id !== tag.id);
      } else {
        return [...prev, tag.id];
      }
    });
  };

  return (
    <button
      type='button'
      onClick={onTagToggle}
      className={classNames(styles.tagButton, styles.tagButtonFollow, {
        [styles.isActive]: selectedTopicsIds.includes(tag.id),
      })}
    >
      {tag.title}
      <span className={styles.buttonIcon}>
        <Plus />
      </span>
    </button>
  );
};

export default FollowTagBullet;
