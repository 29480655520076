import { motion } from 'framer-motion';

import Spinner from '../Spinner/Spinner';
import styles from './Loader.module.scss';

const Loader = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.1 }}
      className={styles.holder}
    >
      <Spinner />
    </motion.div>
  );
};

export default Loader;
