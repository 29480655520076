import { LoaderFunctionArgs, TypedResponse } from '@remix-run/node';

export enum AppType {
  Default = 'default',
  Raw = 'raw',
}

export interface PaginatedResponse<T> {
  data: T[];
  links: {
    first: string;
    last: string;
    prev: string | null;
    next: string | null;
  };
  meta: {
    current_page: number;
    from: number;
    last_page: number;
    path: string;
    per_page: number;
    to: number;
    total: number;
  };
}

export type Article = {
  id: number;
  slug: string;
  title: string;
  description: string;
  comments: Comment[];
  media: ArticleMediaData;
  date: {
    utc: string;
    human_readable: string;
  };
  published_at: string;
  highlights: ArticleHighlight[];
  tags: Tag[];
  is_auto_generated: boolean;
  auto_generation_version: number;
  tag_stats: TagStatistics;
  top_tags_to_covers: Tag[];
  votes_meta: VotesMeta;
  did_image_failed_test: boolean;
  is_reference_image_used: string;
  image_explanation?: string;
  references?: Reference[];
  votes_count: number;
};

export type Reference = {
  id: number;
  url: string;
  scraper_title?: string;
  scraper_icon?: string;
};

export type ArticleMediaData = {
  thumbnail: {
    responsive: string;
    original: string;
    large: string;
    meta: string;
    credits: string;
    is_ai_generated?: boolean;
  };
};

export type ArticleHighlight = {
  id: number;
  title: string;
  order: number;
};

export type Tag = {
  id: number;
  slug: string;
  title: string;
  media?: {
    thumbnail: [
      {
        small: string;
        meta: string;
      },
    ];
  };
  articles_count: number;
  is_favorite_by_current_user?: boolean;
  type: string;
  articles?: Article[];
};

export type ArticlesLoaderResponse = {
  data: Article[];
};

export interface User {
  email: string;
  name: string;
  gender: string;
  avatar?: { small: string };
  stats: UserStats;
  is_admin: boolean;
  favorite_tags: MinimalTagResource[];
  ignored_tags: Tag[];
  main_tags: MinimalTagResource[];
  mailer_lite_groups: MailerLiteGroup[];
}

export interface MailerLiteGroup {
  id: number;
  name: string;
  external_id: string;
}

export interface MinimalTagResource {
  title: string;
  slug: string;
  id: number;
}

export interface UserStats {
  comments: { count: number };
  topics: { follow_count: number };
  votes: {
    down_count: number;
    up_count: number;
  };
}

export type MetaTag = {
  title?: string;
  name?: string;
  content?: string;
  property?: string;
  tagName?: string;
  rel?: string;
  href?: string;
};

export type TagStatistics = {
  formula?: string;
  formula_v2?: string;
  formula_result?: string;
  formula_result_v2?: string;
  updated_formula_result?: string;
  title?: string;
  references_count_v2?: string;
  sum_v2?: string;
};

export type VotesMeta = {
  current_user_vote_type: 'up' | 'down' | null;
  up_vote_count: number;
  down_vote_count: number;
  vote_sum: number;
};

export type Comment = {
  id: number;
  comment_model_type: string;
  content: string;
  user: User;
  date: {
    utc: string;
    human_readable: string;
  };
  votes_meta: VotesMeta;
};

export type UserVote = {
  overview_model_type?: 'vote' | 'comment';
  comment_model_type: string;
  user: User;
  model: Article;
  type: 'up' | 'down';
};

export type UserComment = {
  overview_model_type?: 'vote' | 'comment';
  id: number;
  comment_model_type: string;
  content: string;
  user: User;
  date: {
    utc: string;
    human_readable: string;
  };
  votes_meta: VotesMeta;
  model: Article;
};

export type LoaderDataType<
  LoaderT extends (args: LoaderFunctionArgs) => Promise<TypedResponse<object>>,
> = Awaited<ReturnType<Awaited<ReturnType<LoaderT>>['json']>>;
